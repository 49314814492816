import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import "./Orderhantering.css";
//import Header from "../components/Header";
import Select from "../components/Select";
import Modal from "../components/Modal-ConfirmAlert";
import API from "../utils/API";
import { fetchOrder } from "../Redux/actions/orderAction";
import { logout } from "../Redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function Orderhantering() {
  const [number, setNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [latestChange, setLatestChange] = useState("");
  const [allShops, setAllShops] = useState([]);
  const [allShopsIds, setAllShopsIds] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [theShop, setTheShop] = useState("");
  const [theOrderType, setTheOrderType] = useState("");
  const [theCompany, setTheCompany] = useState("");
  const [theStartDate, setTheStartDate] = useState("");
  const [theEndDate, setTheEndDate] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredOrdersPagewise, setFilteredOrdersPagewise] = useState([]);
  const [expandedFilteredOrderList, setExpandedFilteredOrderList] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const { orderInfo } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const closeModalHandler = () => setShowModal(false);

  const startDateBeforeEndDate = (startDate,endDate) => {
      if(startDate && endDate) {
        let theStartDate = new Date(startDate).getTime();
        let theEndDate = new Date(endDate).getTime();
        if(theStartDate > theEndDate){
          setShowModal(true);
          setTitle("Startdatumet kan inte ligga efter slutdatumet");
        }
      }
  }

  useEffect(() => {
    startDateBeforeEndDate(theStartDate,theEndDate);
  }, [theStartDate]);

  useEffect(() => {
    startDateBeforeEndDate(theStartDate,theEndDate);
  }, [theEndDate]);

  useEffect(() => {
    const getLatestOrderChange = async () => {
      await API.getLastOrderChange()
          .then((response) => {
            setLatestChange(response.data);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    getLatestOrderChange();
  }, []);

  useEffect(() => {
    const getAllShops = async () => {
      await API.getAllShop("")
          .then((response) => {
            let shops = [];
            let shopsIds = [];
            response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
              shops.push(shop.Name);
              shopsIds.push(shop.Code);
            });
            setAllShops(shops);
            setAllShopsIds(shopsIds);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    getAllShops();
  }, []);

  /* Deprecated
  useEffect(() => {
    const getAllCompanies = async () => {
      await API.getCompanyList("")
          .then((response) => {
            setAllCompanies(response.data);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    getAllCompanies();
  }, []);
   */
  const fetchTheOrder = (e) => {
    e.preventDefault();

    setLoadingSpinner(true);

    if (number === "" || null) {
      setShowModal(true);
      setTitle("Ange kontrollnummer");
    } else if (isNaN(number)) {
      setShowModal(true);
      setTitle("Ett ordernummer kan bara innehålla siffror");
    } else {
      API.getOrder(number).then((response) => {
        if(response.status === 200){
          setLoadingSpinner(false);
          dispatch({
            type: "GET_ORDER_SUCCESS",
            payload: response.data,
          });
        } else if (response.status === 401) {
          dispatch(logout());
        } else if (response.status === 404) {
          setShowModal(true);
          setTitle("Ordern finns ej");
        }
      });
    }
  }

  const fetchTheOrders = (e) => {
    e.preventDefault();

    setLoadingSpinner(true);

    setCurrentPage(1);

    const data = {
      byshop: theShop,
      bycompany: theCompany,
      bytype: theOrderType === "Webborder" ? "web" : "company",
      fromdate: theStartDate,
      todate: theEndDate
    };

    API.getCollatedOrdersByFilter(data).then((response) => {
      if(response.status === 200){
        const chunkSize = 10;
        const chunkList = [];
        for (let i = 0; i < response.data.length; i += chunkSize) {
          chunkList.push(response.data.slice(i, i + chunkSize));
        }
        setLoadingSpinner(false);
        setFilteredOrders(response.data);
        setFilteredOrdersPagewise(chunkList);
      } else if (response.status === 401) {
        dispatch(logout());
      } else if (response.status === 404) {
        setShowModal(true);
        setTitle("Ordrar finns ej");
      }
    });
  }

  const cleanUpFilter = (e) => {
    e.preventDefault();

    setTheShop("");
    setTheOrderType("");
    setTheCompany("");
    setTheStartDate("");
    setTheEndDate("");

    document.getElementById("ordersForm").reset();
  }

  const clickedOrder = (e) => {
    e.preventDefault();

    dispatch(fetchOrder(encodeURI(e.target.getAttribute("value"))));

    document.getElementById("orderHead").scrollIntoView({behavior: 'smooth'});
  }

  const clickedOrderRow = (e) => {
    e.preventDefault();

    if(e.target.parentNode.getElementsByTagName("a")[0])
      dispatch(fetchOrder(encodeURI(e.target.parentNode.getElementsByTagName("a")[0].getAttribute("value"))));

    document.getElementById("orderHead").scrollIntoView({behavior: 'smooth'});
  }

  const clickedPage = (e) => {
    e.preventDefault();

    let clickedPageButton = e.target;

    let pageButtons = document.getElementsByClassName("orderhantering__pageButton");

    for(let pageButton of pageButtons){
      pageButton.classList.remove("orderhantering__pageButtonClicked");
    }

    clickedPageButton.classList.add("orderhantering__pageButtonClicked");
    setCurrentPage(clickedPageButton.value);
  }

  return (
    <div className="orderhantering">
      {/* <Header title="Orderhantering" /> */}
      <h5 className="orderhantering__lastOrderChange">Senaste ändring i systemet: { latestChange.datechanged !== undefined ? latestChange.datechanged.slice(0, (latestChange.datechanged.length - 5)).replace("T"," ") : " Senaste ändringen ej tillgänglig"}</h5>
      <form id="ordersForm">
        <div className="orderhantering__inputContainer">
          <select className="orderhantering__select">
            <option className="orderhantering__optionText" value="">
              Ordernummer
            </option>
            {/*
            <option>Ramnummer</option>
            <option>Fakturanummer</option>
            */}
          </select>
          <input
            className="orderhantering__inputText"
            placeholder="Sök..."
            type="text"
            name="inputText"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />

          <button
            className="orderhantering__searchBtn"
            type="submit"
            onClick={(e) => fetchTheOrder(e)}
          >
            SÖK
          </button>
        </div>
        <hr />
        <div className="orderhantering__filter">
          <Modal
              showModal={showModal}
              closeModalHandler={closeModalHandler}
              title={title}
              buttonName="Stäng"
          />
          <div className="orderhantering__filterOpt">
            <Typography variant="string" className="orderhantering__filterText">Filtrera</Typography>
            <Select value={theShop} onChange={(e) => setTheShop(e.target.value)} title="Alla butiker" options={allShops} optionValues={allShopsIds} />
            <Select value={theOrderType} onChange={(e) => setTheOrderType(e.target.value)} title="Ordertyp" options={["Förmånsorder","Webborder"]} />
            <Select value={theCompany} onChange={(e) => setTheCompany(e.target.value)} title="Alla företag" options={allCompanies} />
            <label htmlFor="orderStartDate">Startdatum:</label>
            <input value={theStartDate} onChange={(e) => setTheStartDate(e.target.value)} className="orderhantering__inputTypeDate" type="date" id="orderStartDate" name="orderStartDate" />
            <label htmlFor="orderEndDate">Slutdatum:</label>
            <input value={theEndDate} onChange={(e) => setTheEndDate(e.target.value)} className="orderhantering__inputTypeDate" type="date" id="orderEndDate" name="orderEndDate" />
            {/*
            <Select title="Datumintervall" options={["Ramnummer","Fakturanummer"]} />
            */}
            {/*
            <Select title="Förmånsfönster" options={["Ramnummer","Fakturanummer"]} />
            */}
            {(theShop || theOrderType || theCompany || theStartDate || theEndDate) && <button onClick={cleanUpFilter} className="orderhantering__cleanUpFilterButton"><ClearIcon className="orderhantering__icons" /> <span>Rensa filter</span></button>}
            <button
                className="orderhantering__filterSearchBtn"
                onClick={(e) => fetchTheOrders(e)}
            >
              Sök via filter
            </button>
          </div>
        </div>
      </form>
      {filteredOrders.length > 0 && (
          <>
            { loadingSpinner && <div className="orderhantering__loadingDiv"><h1>Laddar</h1><div className="orderhantering__loader"></div></div> }
            <div className="orderhantering__horizontalLine"></div>
            <div className="orderhantering__orderList">
              <div className="orderhantering__orderListExpandHead">
                <h4>Sökresultat: <b>{filteredOrders.length} ordrar</b></h4>
                {expandedFilteredOrderList ? (<button className="orderhantering__expandButtons" onClick={() => setExpandedFilteredOrderList(false)}><ExpandLessIcon className="orderhantering__expandIcons" /></button>) : (<button className="orderhantering__expandButtons" onClick={() => setExpandedFilteredOrderList(true)}><ExpandMoreIcon className="orderhantering__expandIcons" /></button>)}
              </div>
              {expandedFilteredOrderList && (
                  <>
                    <div className="orderhantering__tableContainer">
                      <table className="orderhantering__orderListOrders">
                          <tr className="orderhantering__rowColumnsHeader">
                            <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5>Ordernummer</h5></th>
                            <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5>Orderstatus</h5></th>
                            <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5>Namn</h5></th>
                            <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5>Datum</h5></th>
                            <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5>Fakturanummer</h5></th>
                            <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5>Fakturastatus</h5></th>
                            <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5>Butik</h5></th>
                          </tr>
                        { filteredOrdersPagewise.length > 0 && filteredOrdersPagewise[(currentPage-1)].map((order, index) => (
                            <tr key={index} onClick={(e) => clickedOrderRow(e)} className={`orderhantering__tr ${order.isInvoiced ? "orderhantering__invoiced" : "orderhantering__notInvoiced"}`}>
                              <td className="orderhantering__rowColumns"><h4><b><a className="orderhantering__orderLink" value={order.OrderNo} onClick={(e) => clickedOrder(e)}>{order.OrderNo}</a></b></h4></td>
                              <td className="orderhantering__rowColumns"><h4>{order.OrderStatusText.StatusText}</h4></td>
                              <td className="orderhantering__rowColumns"><h4>{`${order.FirstName} ${order.LastName}`}</h4></td>
                              <td className="orderhantering__rowColumns"><h4>{order.OrderDate.slice(0,10)}</h4></td>
                              <td className="orderhantering__rowColumns">
                                  {order.InvoiceNumbers.length === 0 ? "-" : null}
                                  {order.InvoiceNumbers.length > 0 && order.InvoiceNumbers.map((invoiceNumber, index, array) => (
                                      <h4>{invoiceNumber}{index !== (array.length-1) ? ", " : null}</h4>
                                  ))}
                              </td>
                              <td className="orderhantering__rowColumns"><h6 className="orderhantering__invoice">{order.isInvoiced ? "FAKTURERAD" : "EJ FAKTURERAD"}</h6></td>
                              <td className="orderhantering__rowColumns"><h4>{order.ShopName}</h4></td>
                            </tr>
                        ))}
                      </table>
                    </div>
                    <div className="orderhantering__horizontalLine"></div>
                    {filteredOrdersPagewise.length > 1 && filteredOrdersPagewise.map((orders,index,array) =>(
                        <button key={index} onClick={(e) => clickedPage(e)} className="orderhantering__pageButton" value={(index+1)}>{(index+1)}</button>
                    ))}
                  </>
              )}
            </div>
          </>)}
          <div id="orderHead"></div>
    </div>
  );
}

export default Orderhantering;
